import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    Stack,
    Step,
    StepLabel,
    Stepper,
    TableContainer,
    Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/auth/useAuthContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify';
import palette from 'src/theme/palette';
import axios from '../utils/Axios';
import Table from 'src/theme/overrides/Table';
import { TableListHead } from 'src/sections/@dashboard/bookings';
import Scrollbar from 'src/components/scrollbar/Scrollbar';

const steps = ['Install App', 'Add Site ID', 'Connected'];

const ConnectWebflow = () => {
    const { user, setGlobalUser } = useAuthContext();

    const defaultValues = useMemo(
        () => ({
            websiteUrl: '',
        }),
        []
    );

    const methods = useForm({ defaultValues });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h4" gutterBottom>
                    Connect Webflow
                </Typography>
            </Stack>
           
            <Card sx={{ p: 3 }}>
                <div >
                    <Box
                        style={{
                            width: '100%',
                            margin: 'auto',
                            background: '#00ab550f',
                            padding: '1.5rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <Typography variant="subtitle2" gutterBottom>
                            Install Webflow App
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    opacity: '0.5',
                                }}
                                gutterBottom
                            >
                                Follow the link and authorize the webflow site(s) you wish to connect with FlowBookings
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: '270px',
                            }}
                        >
                            <Button
                                variant="contained"
                                endIcon={<Iconify icon="tabler:external-link" />}
                                onClick={(event) => { 
                                    event.preventDefault();
                                    window.open('https://app-api.flowbookings.io/authorize', '_blank');
                                }}
                            >
                                Install Webflow App
                            </Button>
                        </Box>
                    </Box>

                <Typography mt={10} style={{ textAlign: 'center' }} variant="h5" gutterBottom>
                    Connected Sites
                </Typography>
                <Typography
                    style={{ textAlign: 'center' }}
                    variant="subtitle1"
                    sx={{
                        opacity: '0.5',
                    }}
                    gutterBottom
                >
                    The following Webflow site(s) are connected to your Flowbookings account.
                </Typography>

                <WebflowSitesTable sites={user?.config?.webflowSites || []} />

                </div>
            </Card>
        </div>
    );
};

export default ConnectWebflow;

const WebflowSitesTable = ({ sites = [] }) => {
  const tableStyles = {
    wrapper: {
      width: '100%',
      border: '1px solid #f3f4f6',
      borderRadius: '8px',
      backgroundColor: '#fff'
    },
    header: {
      width: '100%',
      backgroundColor: '#f9fafb',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      padding: '12px 24px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    headerText: {
      color: '#6B7280',
      fontSize: '14px',
      fontWeight: 500,
    },
    noSitesMessage: {
      width: '100%',
      padding: '16px 24px',
      textAlign: 'left',
      color: '#FF4842',
      fontSize: '14px'
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      padding: '16px 24px',
      borderTop: '1px solid #f3f4f6'
    },
    cellText: {
      color: '#212B36',
      fontSize: '14px'
    },
    button: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '6px 12px',
      backgroundColor: '#E8F6F3',
      color: '#00A76F',
      borderRadius: '6px',
      fontSize: '14px',
      fontWeight: 500,
      border: 'none',
      cursor: 'pointer'
    }
  };

  const TableHeader = () => (
    <div style={tableStyles.header}>
      <div>
        <span style={tableStyles.headerText}>Name</span>
      </div>
      <div>
        <span style={tableStyles.headerText}>Domain</span>
      </div>
    </div>
  );

  const NoSitesMessage = () => (
    <div style={tableStyles.noSitesMessage}>
      Webflow Not Connected! Please install and login on the webflow app to connect your site.
    </div>
  );

  const SiteRow = ({ siteName, domain }) => (
    <div style={tableStyles.row}>
      <div>
        <span style={tableStyles.cellText}>{siteName}</span>
      </div>
      <div>
        <span style={tableStyles.cellText}>{domain}</span>
      </div>
    </div>
  );

  return (
    <div style={tableStyles.wrapper}>
      <TableHeader />
      {sites.length === 0 ? (
        <NoSitesMessage />
      ) : (
        sites.map((site, index) => (
          <SiteRow
            key={site.siteId}
            siteName={site.siteName}
            domain={`${site.siteId}.webflow.io`}
          />
        ))
      )}
    </div>
  )
};